import firebase from 'firebase/app'
import 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/database'
import 'firebase/functions'

let productionConfig = {
  apiKey: API_KEY,
  authDomain: 'your-psychologist.firebaseapp.com',
  databaseURL: 'https://your-psychologist.firebaseio.com',
  projectId: 'your-psychologist',
  storageBucket: 'your-psychologist.appspot.com',
  messagingSenderId: '90451760569',
  appId: '1:90451760569:web:a7d5bbb49638f489'
}

let developmentConfig = {
  apiKey: API_KEY,
  authDomain: 'ikow-dev.firebaseapp.com',
  databaseURL: 'https://ikow-dev.firebaseio.com',
  projectId: 'ikow-dev',
  storageBucket: 'ikow-dev.appspot.com',
  messagingSenderId: '656049931399',
  appId: '1:656049931399:web:d937beb2651b8f8eee6422',
  measurementId: 'G-DZLW90L8F5'
}

if (NODE_ENV == 'production') {
  console.info('PRODUCTION')
  firebase.initializeApp(productionConfig)
} else {
  console.info('DEVELOP')
  firebase.initializeApp(developmentConfig)
}

let functions = firebase.functions()

let db = firebase.firestore()
let date = firebase.firestore.Timestamp.fromDate(new Date())
let auth = firebase.auth()

let storage = firebase.storage()
let database = firebase.database()
export { db, auth, storage, date, database, functions }
