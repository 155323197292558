import React from 'react'

import { IkowButton } from 'ikowbutton'
import 'ikowbutton/dist/index.css'

const queryString = window.location.search
console.info(queryString)
const urlParams = new URLSearchParams(queryString)

const App = () => {
  var apiKey = urlParams.get('apiKey')
  var color = urlParams.get('color')
  return <IkowButton apiKey={apiKey} color={color} nameBusiness='iKow' />
}

export default App
