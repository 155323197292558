import createSagaMiddleware from 'redux-saga'
import Reactotron from './ReactotronConfig'
import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import rootReducer from './reducers'
import rootSaga from './sagas'
import storage from 'redux-persist/lib/storage'

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware()
  const persistConfig = {
    key: 'root',
    storage: storage
  }
  const persistedReducer = persistReducer(persistConfig, rootReducer)
  const store = createStore(
    persistedReducer,
    compose(applyMiddleware(sagaMiddleware), Reactotron.createEnhancer())
  )
  sagaMiddleware.run(rootSaga)
  const persistor = persistStore(store)
  return { store, persistor }
}

export default configureStore
