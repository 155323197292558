import { put, select, take, call } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { auth, db } from '../fireStoreConfig'
import { helpers } from '../helpers'
import adviserRedux from '../reducers/adviserRedux'
import categoryRedux from '../reducers/categoryRedux'

const getPanelStates = (state) => state.PanelRedux
const getCategoryStates = (state) => state.CategoryRedux
const getLocationState = (state) => state.LocationRedux

export function* getAdviserByIdBusiness() {
  const panelRedux = yield select(getPanelStates)
  const { receivedAdviser } = adviserRedux
  // Obtener lista de asesores
  const channel = new eventChannel((emiter) => {
    var dataTest = []
    const listener = db
      .collection('enviroment/prod/users')
      .where('business', 'array-contains', panelRedux.apiKey)
      .where('rol', '==', ['Bussiness_User'])
      .limit(20)
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data())
        dataTest = data != null ? helpers.jsonToArrayWithId(data) : []
        emiter({ data: dataTest })
      })

    return () => {
      listener.off()
    }
  })

  while (true) {
    const { data } = yield take(channel)
    yield put(receivedAdviser(data))
  }
}

export function* getAdviserByCategory(action) {
  const panelRedux = yield select(getPanelStates)
  const locationRedux = yield select(getLocationState)

  const categoryUID = action.data.uid

  const { receivedAdviser } = adviserRedux
  const { receivedCategorySelect } = categoryRedux
  var allAdviserByCategory = yield call(function () {
    var officeSelect = locationRedux.officeSelect

    return new Promise(function (resolve, reject) {
      if (officeSelect && Object.keys(officeSelect).length !== 0) {
        let citiesRef = db.collection(`enviroment/prod/users`)
        citiesRef
          .where('business', '==', [panelRedux.apiKey])
          .where('categories', 'array-contains', categoryUID)
          .where('city', '==', locationRedux.officeSelect.city.name)
          .get()
          .then((querySnapshot) => {
            const dataTest = querySnapshot.docs.map((doc) => {
              var data = doc.data()
              data.uid = doc.id
              return data
            })
            resolve(dataTest)
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        let citiesRef = db.collection(`enviroment/prod/users`)
        citiesRef
          .where('business', '==', [panelRedux.apiKey])
          .where('categories', 'array-contains', categoryUID)
          .get()
          .then((querySnapshot) => {
            const dataTest = querySnapshot.docs.map((doc) => {
              var data = doc.data()
              data.uid = doc.id
              return data
            })
            resolve(dataTest)
          })
          .catch((error) => {
            console.error(error)
          })
      }
    })
  })

  yield put(receivedAdviser(allAdviserByCategory))
  yield put(receivedCategorySelect(action.data))
}

export function* getAdviserBySede(action) {
  const panelRedux = yield select(getPanelStates)
  const categoryRedux = yield select(getCategoryStates)
  const { receivedAdviser } = adviserRedux

  const sede = action.data

  var advisers = yield call(function () {
    return new Promise(function (resolve, reject) {
      if (categoryRedux.categorySelect === null) {
        let citiesRef = db.collection(`enviroment/prod/users`)
        citiesRef
          .where('business', '==', [panelRedux.apiKey])
          .where('rol', 'array-contains', 'Bussiness_User')
          .where('city', '==', sede.city.name)
          .limit(40)
          .get()
          .then((querySnapshot) => {
            const dataTest = querySnapshot.docs.map((doc) => {
              var data = doc.data()
              data.uid = doc.id
              return data
            })
            resolve(dataTest)
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        let citiesRef = db.collection(`enviroment/prod/users`)

        if (sede === undefined) {
          citiesRef
            .where('business', '==', [panelRedux.apiKey])
            .where(
              'categories',
              'array-contains',
              categoryRedux.categorySelect.uid
            )
            .limit(40)
            .get()
            .then((querySnapshot) => {
              const dataTest = querySnapshot.docs.map((doc) => {
                var data = doc.data()
                data.uid = doc.id
                return data
              })
              resolve(dataTest)
            })
            .catch((error) => {
              console.error(error)
            })
        } else {
          citiesRef
            .where('business', '==', [panelRedux.apiKey])
            .where(
              'categories',
              'array-contains',
              categoryRedux.categorySelect.uid
            )
            .where('city', '==', sede.city.name)
            .limit(40)
            .get()
            .then((querySnapshot) => {
              const dataTest = querySnapshot.docs.map((doc) => {
                var data = doc.data()
                data.uid = doc.id
                return data
              })
              resolve(dataTest)
            })
            .catch((error) => {
              console.error(error)
            })
        }
      }
    })
  })
  yield put(receivedAdviser(advisers ?? []))
}
