import React from 'react'
import styles from '../../styles.module.css'
import { Container, Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { CardAdviser } from './cardAdviser'
import { Footer } from './footer'
import { AdviserTypes } from '../../reducers/adviserRedux'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { UserTypes } from '../../reducers/userRedux'
import { LocationTypes } from '../../reducers/locationRedux'

import { makeStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import NativeSelect from '@material-ui/core/NativeSelect'

import FilterListIcon from '@material-ui/icons/FilterList'
import TravelExploreIcon from '@material-ui/icons/Explore'
// import TravelExploreIcon from '@material-ui/icons/TravelExplore'
import Chip from '@material-ui/core/Chip'
import MessageView from './../message/messageView'

class Home extends React.Component {
  constructor(props) {
    super(props)
    var country = { id: 82 }
    this.props.requestOffices()

    this.state = {
      age: '',
      name: '',
      activeFilter: false,
      office: {},
      isActiveMessage: false
    }
  }

  render() {
    return !this.state.isActiveMessage ? (
      <div className={styles.containerHome}>
        {this.header()}
        {this.body()}
      </div>
    ) : (
      <MessageView
        onClickBack={() =>
          this.setState({ isActiveMessage: !this.state.isActiveMessage })
        }
      ></MessageView>
    )
  }

  handleChange = (event) => {
    const { offices } = this.props.locationRedux
    const uid = event.target.value

    const filter = offices.filter((office) => office.uid === uid)
    this.props.setOffice(filter[0])
    this.props.getAdviserBySedeAndCategory(filter[0])
    this.setState({
      activeFilter: false
    })
  }

  changeSede(value) {}

  handleDelete() {
    this.props.setOffice(undefined)
    console.info('You clicked the delete icon.')
  }

  header() {
    const { color, nameBusiness } = this.props.panelRedux
    const { user } = this.props.userRedux
    const { offices, officeSelect } = this.props.locationRedux

    const classes = useStyles
    return (
      <div className={styles.homeHeader}>
        <Container>
          <Row>
            <Col xs={12}>
              <Row>
                <img width={'45px'} src={user.urlPhoto}></img>
                <Col>
                  <div>
                    <b>{user.nickName}</b>
                  </div>
                  <div>{user.email}</div>
                </Col>
                <div>
                  <ExitToAppIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.props.logout()}
                  ></ExitToAppIcon>
                </div>
              </Row>
            </Col>
            <div
              style={{
                width: '100%',
                height: '1px',
                backgroundColor: 'rgba(0,0,0,.1)',
                margin: '8px 2px'
              }}
            ></div>

            <Col xs={10} style={{ padding: '0px' }}>
              {this.state.activeFilter
                ? this.selectSedes(classes, offices, officeSelect)
                : this.categories()}
            </Col>
            <Col xs={2} style={{ paddingHorizontal: '10px' }}>
              {this.buttonSedes()}
            </Col>
            {officeSelect != undefined &&
              Object.keys(officeSelect).length !== 0 &&
              this.sedeSelected(officeSelect)}
          </Row>
        </Container>
      </div>
    )
  }

  sedeSelected(officeSelect) {
    return (
      <div>
        <Col xs={12} style={{ padding: '0px' }}>
          <Chip
            label={officeSelect.city.name}
            onDelete={() => this.handleDelete()}
            variant='outlined'
          />
        </Col>
      </div>
    )
  }

  selectSedes(classes, offices, officeSelect) {
    return (
      <FormControl className={classes.formControl}>
        <FormHelperText>{'Ubicación/Sedes'}</FormHelperText>
        <NativeSelect
          className={(classes.selectEmpty, classes.select)}
          value={officeSelect !== undefined ? officeSelect.uid : 'Seleciona'}
          name='age'
          inputProps={{ style: { fontSize: 13 } }}
          onChange={(event) => this.handleChange(event)}
        >
          <option value='-1'>{'Ver todos los asesores'}</option>
          {offices.map((element, index) => {
            return (
              <option
                key={index}
                value={element.uid}
              >{`Sede ${element.city.name}`}</option>
            )
          })}
        </NativeSelect>
      </FormControl>
    )
  }

  buttonSedes() {
    return (
      <div
        onClick={() =>
          this.setState({
            activeFilter: !this.state.activeFilter
          })
        }
        style={{
          display: 'flex',
          flexDirection: 'column',
          cursor: 'pointer'
        }}
      >
        <div>
          <TravelExploreIcon></TravelExploreIcon>
        </div>
        {/* <div
          className={styles.colorInitial}
          style={{
            fontWeight: this.state.activeFilter ? 'bold' : 'normal'
          }}
        >
          {'Sedes'}
        </div> */}
      </div>
    )
  }

  categories() {
    const { categoryListBusiness, categorySelect } = this.props.categoryRedux
    const { color } = this.props.panelRedux
    return (
      <Container>
        <Row>{'Areas de Ikow'}</Row>
        <Row>
          <div className={styles.horizontalScrollContainer}>
            {categoryListBusiness.map((category, index) => {
              var categoryCurrent = null
              if (categorySelect != null) {
                categoryCurrent = categorySelect.uid
              }
              return (
                <div
                  key={index}
                  style={{
                    cursor: 'pointer',
                    opacity: category.uid == categoryCurrent ? 1 : 0.5,
                    background: color
                  }}
                  onClick={() => {
                    this.props.requestAdviserByCategory(category)
                  }}
                >
                  {category.name}
                </div>
              )
            })}
          </div>
        </Row>
      </Container>
    )
  }

  body() {
    const { adviserList } = this.props.adviserRedux
    const { user } = this.props.userRedux
    return (
      <div className={styles.homeBody}>
        <div className={styles.homeBodyContainer}>
          <Container className={styles.verticalScrollable}>
            <Row>
              {adviserList.map((adviser, index) => {
                return (
                  <CardAdviser
                    key={index}
                    index={index}
                    user={user}
                    adviser={adviser}
                    onclickMessage={() => {
                      this.props.setAdviserSelect(adviser)
                      this.setState({
                        isActiveMessage: true
                      })
                    }}
                  ></CardAdviser>
                )
              })}
              {adviserList.length == 0 && (
                <div className={styles.homeBodyContainerNoAdvisor}>
                  <div> {'No se encuentra asesores en este momento'}</div>
                </div>
              )}
            </Row>
          </Container>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  select: {
    '&:before': {
      borderColor: 'red'
    },
    '&:hover:not(.Mui-disabled):before': {
      borderColor: 'var(--galaxy-blue)'
    }
  }
}))

const mapStateToProps = (state) => ({
  userRedux: state.UserRedux,
  adviserRedux: state.AdviserRedux,
  categoryRedux: state.CategoryRedux,
  panelRedux: state.PanelRedux,
  locationRedux: state.LocationRedux
})
const mapDispatchToProps = (dispatch) => ({
  requestAdviserByCategory: (data) => {
    dispatch({ type: AdviserTypes.REQUEST_ADVISER_BY_CATEGORY, data: data })
  },
  getAdviserBySedeAndCategory: (data) => {
    dispatch({ type: AdviserTypes.REQUEST_ADVISER_BY_SEDE, data: data })
  },
  setOffice: (data) => {
    dispatch({ type: LocationTypes.SET_OFFICE_SELECT, data: data })
  },
  requestOffices: (data) => {
    dispatch({ type: LocationTypes.REQUEST_OFFICES, data: data })
  },
  requestCities: (data) => {
    dispatch({ type: LocationTypes.REQUEST_CITIES, data: data })
  },
  logout: (data) => {
    dispatch({ type: UserTypes.LOG_OUT })
  },
  requestStateWithIdCountry: (data) => {
    dispatch({ type: LocationTypes.REQUEST_COUNTRY, data: data })
  },
  setAdviserSelect: (data) => {
    dispatch({ type: AdviserTypes.SET_ADVISER_SELECT, data: data })
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(Home)
