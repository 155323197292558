import { put, take, select } from 'redux-saga/effects'
import { db, functions } from './../fireStoreConfig'
import firebase from 'firebase/app'

import { eventChannel } from 'redux-saga'
import { helpers } from './../helpers'

import messageRedux from '../reducers/messageRedux'

const getUserReducer = (state) => state.UserRedux

export function* getMessages(action) {
  let userRedux = yield select(getUserReducer)
  const { receiveMessage } = messageRedux
  const user = userRedux.user
  const adviser = action.data

  const uid = user.uid
  const totalMessageChat = 40

  // Obtener lista de asesores
  const channel = new eventChannel((emiter) => {
    const uidAdviser = adviser.uid
    var idGroupChat = uidAdviser + '-' + uid

    const listener = db
      .collection(`enviroment/prod/messages`)
      .doc(idGroupChat)
      .collection(idGroupChat)
      .orderBy('timestamp', 'desc')
      .limit(totalMessageChat)
      .onSnapshot((snapShots) => {
        const messages = snapShots.docs.map((doc) => {
          return doc.data()
        })
        var list = messages !== null ? helpers.jsonToArrayWithId(messages) : []

        var orderMessage = list.sort(function (a, b) {
          return a.timestamp - b.timestamp
        })

        emiter({ data: orderMessage })
      })

    return () => {
      listener.off()
    }
  })

  while (true) {
    const { data } = yield take(channel)
    yield put(receiveMessage(data))
  }
}

export function* saveMessage(action) {
  let userRedux = yield select(getUserReducer)
  const emissor = userRedux.user
  const receptor = action.data.userSelect
  const inputTxtChat = action.data.message
  const type = action.data.type ?? 0
  const url = action.data.url ?? ''
  const durationAudio = action.data.durationAudio ?? 0

  const groupChatId = `${emissor.uid}-${receptor.uid}`
  const groupChatId2 = `${receptor.uid}-${emissor.uid}`

  if (inputTxtChat !== '' || type != 0) {
    var timeDate = firebase.firestore.Timestamp.fromDate(new Date())

    var documentReference = db
      .collection(`enviroment/prod/messages`)
      .doc(groupChatId)
      .collection(groupChatId)
      .doc()

    db.runTransaction(async (transaction) => {
      await transaction.set(documentReference, {
        idFrom: emissor.uid,
        idTo: receptor.uid,
        timestamp: timeDate,
        content: inputTxtChat,
        type: type,
        url: url,
        durationAudio: durationAudio
      })
    }).then(() => {
      db.collection(`enviroment/prod/chats`)
        .doc(emissor.uid)
        .collection(emissor.uid)
        .doc(receptor.uid)
        .set({
          uid: receptor.uid,
          nickName: receptor.nickName,
          urlPhoto: receptor.urlPhoto,
          time: timeDate,
          bagde: false,
          message: inputTxtChat
        })
    })

    // salvo en el chat vecino
    var documentReference2 = db
      .collection(`enviroment/prod/messages`)
      .doc(groupChatId2)
      .collection(groupChatId2)
      .doc()

    db.runTransaction(async (transaction) => {
      await transaction.set(documentReference2, {
        idFrom: emissor.uid,
        idTo: receptor.uid,
        timestamp: timeDate,
        content: inputTxtChat,
        type: type,
        url: url,
        durationAudio: durationAudio
      })
    }).then(() => {
      db.collection(`enviroment/prod/chats`)
        .doc(receptor.uid)
        .collection(receptor.uid)
        .doc(emissor.uid)
        .set({
          uid: emissor.uid,
          nickName: emissor.nickName,
          urlPhoto: emissor.urlPhoto,
          time: timeDate,
          bagde: true,
          token: emissor.token,
          message: inputTxtChat
        })
    })
    //

    sendNotificacionChats(emissor, receptor, inputTxtChat)
  }
}

export function sendNotificacionChats(emissor, receptor, message) {
  db.collection(`enviroment/prod/users`)
    .doc(receptor.uid)
    .collection('notificationChat')
    .doc(receptor.uid)
    .set({
      uid: receptor.uid,
      bagde: true,
      count: 1
    })
    .then(() => {
      db.collection(`enviroment/prod/users`)
        .doc(receptor.uid)
        .get()
        .then((snap) => {
          var user = snap.data()
          const getSelf = functions.httpsCallable('notificationChatToUser')
          getSelf({
            uid: receptor.uid,
            name: emissor.nickName,
            urlPhoto: emissor.urlPhoto,
            token: user['token'],
            message: message
          }).catch((error) => {
            console.error(error)
          })
        })
        .catch((error) => {
          console.error(error)
        })
    })
}

export function* getChats(action) {
  let userRedux = yield select(getUserReducer)
  const { receiveChats } = messageRedux
  const user = userRedux.user
  // Obtener lista de chats
  const channel = new eventChannel((emiter) => {
    const listener = db
      .collection(`enviroment/prod/chats`)
      .doc(user.uid)
      .collection(user.uid)
      .orderBy('time', 'desc')
      .onSnapshot((snapShots) => {
        var list = helpers.SnapShopToArrayWithId(snapShots)

        emiter({ data: list })
      })

    return () => {
      listener.off()
    }
  })

  while (true) {
    const { data } = yield take(channel)
    yield put(receiveChats(data))
  }
}
