import { db, functions } from './fireStoreConfig'

export default {
  callingUpdate: (uidEmmisor, uidReceptor, isCalling, inCalling, hangup) => {
    var data = {
      inCalling: inCalling,
      isCalling: isCalling,
      emmisor: uidEmmisor,
      receptor: uidReceptor,
      hangup: hangup
    }

    var d = new Date()
    var channel = Math.floor(d / 1000).toString()
    data['channel'] = channel

    var route = 'enviroment/prod/users'

    db.collection(route)
      .doc(uidEmmisor)
      .collection('call')
      .doc(uidEmmisor)
      .update(data)

    db.collection(route)
      .doc(uidReceptor)
      .collection('call')
      .doc(uidReceptor)
      .update(data)
  },

  pushCall: (emmisor, receptor) => {
    var addMessage = functions.httpsCallable('incomingCall')
    addMessage({
      enviroment: process.env.REACT_APP_FIREBASE,
      emisorUid: emmisor.uid,
      emisorUrlPhoto: emmisor.urlPhoto,
      emisorToken: emmisor.token,
      emisorName: emmisor.name,
      receptorUid: receptor.uid,
      receptorUrlPhoto: receptor.urlPhoto,
      receptorToken: receptor.token,
      receptorName: receptor.name
    })
      .then((result) => {
        // Read result of the Cloud Function.
      })
      .catch((error) => {
        // Getting the Error details.
        var code = error.code
        var message = error.message
        var details = error.details
        console.error(message)
        // ...
      })
  },

  pushHangupCall: (emmisor, receptor) => {
    var addMessage = functions.httpsCallable('callReject')
    addMessage({
      enviroment: process.env.REACT_APP_FIREBASE,
      emisorUid: emmisor.uid,
      emisorUrlPhoto: emmisor.urlPhoto,
      emisorToken: emmisor.token,
      emisorName: emmisor.name,
      receptorUid: receptor.uid,
      receptorUrlPhoto: receptor.urlPhoto,
      receptorToken: receptor.token,
      receptorName: receptor.name
    })
      .then((result) => {})
      .catch((error) => {
        // Getting the Error details.
        var code = error.code
        var message = error.message
        var details = error.details
        console.error(message)
        // ...
      })
  }
}
