import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { UserTypes } from '../../reducers/userRedux'
import google from '/../../assets/google.png'
import facebook from '/../../assets/facebook.svg'
import incognito from '/../../assets/anonimo.png'

const Circle = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: ${(props) => props.color};
`

class ButtonRedes extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly'
        }}
      >
        <Circle
          color='rgba(1,1,1,0.1)'
          onClick={() => {
            this.props.loginAnonymous({ nickName: 'Incognito' })
          }}
        >
          <img src={incognito} style={{ width: '30px' }}></img>
        </Circle>

        <Circle
          color='rgba(1,1,1,0.1)'
          onClick={() => {
            this.props.connectWithRedes('google')
          }}
        >
          <img src={google} style={{ width: '40px' }}></img>
        </Circle>

        <Circle
          color='rgba(1,1,1,0.1)'
          onClick={() => {
            this.props.connectWithRedes('facebook')
          }}
        >
          <img src={facebook} style={{ width: '50px' }}></img>
        </Circle>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  userRedux: state.userRedux
})
const mapDispatchToProps = (dispatch) => ({
  connectWithRedes: (data) => {
    dispatch({ type: UserTypes.LOGIN_WITH_REDES, data: data })
  },
  loginAnonymous: (data) => {
    dispatch({ type: UserTypes.REQUEST_USER, data: data })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ButtonRedes)
