//**[Effects]
import { takeLatest, fork } from 'redux-saga/effects'
import { VideoCallTypes } from '../reducers/videoCallRedux'
import { UserTypes } from '../reducers/userRedux'
import { SessionTypes } from '../reducers/sesionRedux'

//**[sagas]
import { sendVideoCall } from './videoCallSagas'
import { loginAnonymous, loginWithRedes, logOut } from './userSagas'
import { userListenerAuth } from './userSagas'
import {
  getCountries,
  getStateCountry,
  getCitiesWithIdState,
  getOffices
} from './locationSagas'
import {
  getAdviserByIdBusiness,
  getAdviserByCategory,
  getAdviserBySede
} from './adviserSagas'

import {
  getSessionByAdviser,
  getSessionByUser,
  getSessionIndicator,
  saveSession
} from './sessionSagas'

//**[reducer]

import { AdviserTypes } from '../reducers/adviserRedux'
import { LocationTypes } from '../reducers/locationRedux'
import { MessagesTypes } from '../reducers/messageRedux'

import { getMessages, saveMessage, getChats } from './messageSagas'

export default function* rootSaga() {
  yield takeLatest(VideoCallTypes.REQUEST_CALL, sendVideoCall)
  yield takeLatest(UserTypes.REQUEST_USER, loginAnonymous)
  yield takeLatest(UserTypes.LOGIN_WITH_REDES, loginWithRedes)
  yield takeLatest(UserTypes.LOG_OUT, logOut)

  yield takeLatest(
    AdviserTypes.REQUEST_ADVISER_BY_ID_BUSINESS,
    getAdviserByIdBusiness
  )

  yield takeLatest(
    AdviserTypes.REQUEST_ADVISER_BY_CATEGORY,
    getAdviserByCategory
  )
  yield takeLatest(AdviserTypes.REQUEST_ADVISER_BY_SEDE, getAdviserBySede)

  yield takeLatest(LocationTypes.REQUEST_COUNTRY, getStateCountry)
  yield takeLatest(LocationTypes.REQUEST_CITIES, getCitiesWithIdState)
  yield takeLatest(LocationTypes.REQUEST_OFFICES, getOffices)

  yield takeLatest(MessagesTypes.REQUEST_MESSAGE, getMessages)
  yield takeLatest(MessagesTypes.SAVE_MESSAGE, saveMessage)
  yield takeLatest(MessagesTypes.REQUEST_CHATS, getChats)

  //SESION
  yield takeLatest(SessionTypes.REQUEST_SESSION_BY_ADVISER, getSessionByAdviser)

  yield takeLatest(SessionTypes.REQUEST_SESSION_INDICATOR, getSessionIndicator)
  yield takeLatest(SessionTypes.REQUEST_SESSION_BY_USER, getSessionByUser)
  yield takeLatest(SessionTypes.REQUEST_SESSION_SAVE, saveSession)

  yield fork(getCountries)
  yield fork(userListenerAuth)
}
