import React from 'react'
import HelperCall from '../../../helperCall'
import { Container } from 'react-bootstrap'
import styles from './styles.css'
import classNames from 'classnames'
import { helpers } from '../../../helpers'

export default class ScreenIncomingCall extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      emisor: null,
      receptor: null
    }
  }

  async componentDidMount() {
    var emisor = await helpers.getUserByUid(this.props.emissorUid)
    var receptor = await helpers.getUserByUid(this.props.receptorUid)
    this.setState({
      emisor: emisor,
      receptor: receptor
    })
  }

  answer(emisor, receptor) {
    var hours = new Date().getHours()
    var minutes = new Date().getMinutes()
    var seconds = new Date().getSeconds()
    var timeQuery = hours + ':' + minutes + ':' + seconds
    sessionStorage.setItem('timeCallAnswer', timeQuery)
    HelperCall.callingUpdate(emisor.uid, receptor.uid, false, true, false)
  }

  hangup(emisor, receptor) {
    HelperCall.pushHangupCall(emisor, receptor)
    HelperCall.callingUpdate(emisor.uid, receptor.uid, false, false, false)
  }

  render() {
    const { rol, uid } = this.props.user
    const { emisor, receptor } = this.state

    return (
      <Container
        fluid
        className={classNames(
          styles.smH100,
          'main-content-container',
          'px-4',
          'smH100'
        )}
      >
        <div className={classNames(styles.smH100, 'row', 'smH100')}>
          <audio src={'../tono/sonido.mp3'} autoPlay loop></audio>
          <div
            className={classNames(
              'col-lg-12',
              'text-center',
              'smH100',
              styles.callBox
            )}
          >
            <div className={styles.callContent}>
              <h2>{rol != 'User' ? 'LLamada entrante...' : 'Llamando'}</h2>
              <br></br>
              <button
                className={classNames('btn', 'btn-danger', styles.btnHangOut)}
                onClick={() => this.hangup(emisor, receptor)}
              >
                Colgar
              </button>

              {rol != 'User' && uid != this.props.emissorUid && (
                <button
                  className={classNames('btn', 'btn-success', styles.btnAnswer)}
                  onClick={() => this.answer(emisor, receptor)}
                >
                  Contestar
                </button>
              )}
            </div>
          </div>
        </div>
      </Container>
    )
  }
}
