import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import HelperCall from './../../helperCall'
// import $, { error } from 'jquery'

import MessageIcon from '@material-ui/icons/Message'
import send from '/../../assets/send.png'
import PCMPlayer from 'pcm-player'
import VideocamIcon from '@material-ui/icons/Videocam'
import { TextField } from '@material-ui/core'
import ImageIcon from '@material-ui/icons/Image'
import MicIcon from '@material-ui/icons/Mic'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import ReactLoading from 'react-loading'

import { storage, db } from './../../fireStoreConfig'
import CardMessage from '../card/cardMessage'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'

const player = new PCMPlayer({
  inputCodec: 'Int16',
  channels: 1,
  sampleRate: 44000,
  flushTime: 2000
})

export default class ScreenMessage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      type: 0,
      url: '',
      durationAudio: 0,
      isLoading: false
    }
    this.messagesEndRef = React.createRef()
  }

  componentDidMount() {
    this.scrollToBottom()
  }

  componentDidUpdate() {
    this.scrollToBottom()
  }

  scrollToBottom = () => {
    if (this.props.activeChat) {
      this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  onKeyDown = (event) => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      this.props.onClick()
      this.scrollToBottom()
    }
  }

  render() {
    const { uid } = this.props.user
    return this.props.activeChat ? (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'space-between',
          padding: '10px',
          backgroundColor: 'white',
          borderRadius: '8px',
          padding: '10px 0px'
        }}
      >
        {this.headerAvatar(this.props.user, this.props.adviser)}
        {this.message(uid, player)}
        {this.footer()}
      </div>
    ) : (
      <Col lg={12}>
        <center>
          <p style={{ marginTop: '8%' }}>
            <MessageIcon style={{ color: 'white' }} />
          </p>
          <h4 style={{ width: '400px', marginTop: '1%' }}>{'iKow Chat'}</h4>
        </center>
      </Col>
    )
  }

  message(uid, player) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: this.props.height !== null ? this.props.height : '45vh',
          overflow: 'scroll'
        }}
      >
        {this.props.message.map((data, index) => {
          return (
            <CardMessage
              index={index}
              isRight={uid === data.idFrom}
              user={this.props.user}
              userSelect={this.props.adviser}
              key={index}
              data={data}
              player={player}
            />
          )
        })}
        <div ref={this.messagesEndRef} />
      </div>
    )
  }

  footer() {
    const { isLoading } = this.state
    return (
      <div
        className='col-lg-12'
        style={{
          backgroundColor: '#fff',
          flex: '1'
        }}
      >
        <div
          className='row col-lg-12 border_enacebezado_chat'
          style={{
            padding: '1%',
            backgroundColor: '#fff'
          }}
        >
          {' '}
          <div
            style={{
              width: '100%',
              borderTop: '1px solid #e8e8e8'
            }}
          ></div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%'
            }}
          >
            <div
              style={{
                flex: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {isLoading && (
                <ReactLoading
                  type={'bubbles'}
                  color={'#0095ff'}
                  height={40}
                  width={40}
                />
              )}
              {!isLoading && (
                <ImageIcon
                  onClick={() => {
                    this.inputImageElement.click()
                    // $('#group_image').trigger('click')
                  }}
                ></ImageIcon>
              )}
              <input
                ref={(input) => (this.inputImageElement = input)}
                id='group_image'
                type='file'
                onChange={this.onImageChange}
                className='filetype'
                style={{ display: 'none' }}
              />
              {/* {!isLoading && (
                <MicIcon
                  onClick={() => {
                    this.setState({
                      type: 2,
                      url: '',
                      durationAudio: 10
                    })
                  }}
                ></MicIcon>
              )} */}
            </div>

            <div style={{ flex: 13 }}>
              <TextField
                id='filled-multiline-flexible'
                label='Escribe tu mensaje...'
                style={{ width: '100%', backgroundColor: 'white' }}
                InputProps={{
                  disableUnderline: true
                }}
                InputLabelProps={{
                  style: {
                    color: '#a5a5b0',
                    fontFamily: 'AvenirLigth'
                  }
                }}
                multiline
                rowsMax={4}
                value={this.props.value}
                onChange={this.props.onChange}
              />
            </div>
            <div
              style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <img
                src={send}
                width='30px'
                onClick={() => {
                  this.props.onClick(
                    this.state.type,
                    this.state.url,
                    this.state.durationAudio
                  )
                }}
              ></img>
            </div>
          </div>
        </div>
      </div>
    )
  }

  headerAvatar(sender, receptor) {
    return (
      <div
        style={{
          flex: '1',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignContent: 'start',
          alignItems: 'center',
          marginBottom: '10px',
          paddingBottom: '15px',
          borderBottom: '1px solid #f7f7f7'
        }}
      >
        <div onClick={() => this.props.onClickBack()}>
          <ArrowBackIcon />
        </div>
        <div>
          <center>
            <img
              src={receptor.urlPhoto}
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                imageRendering: 'crisp-edges'
              }}
            />
          </center>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '10px',
            width: '100%'
          }}
        >
          <div>
            <span className='colorSubTitle'>{receptor.nickName}</span>
          </div>
          <div>
            <span className='colorAnteTitle'>
              {receptor.isOnline ? 'Active Now' : 'Off Line'}
            </span>
          </div>
        </div>

        <div>
          <VideocamIcon
            fontSize='large'
            onClick={() => {
              HelperCall.callingUpdate(
                sender.uid,
                receptor.uid,
                true,
                false,
                false
              )
              HelperCall.pushCall(sender, receptor)
            }}
          ></VideocamIcon>
        </div>
      </div>
    )
  }

  onImageChange = (event) => {
    this.setState({
      isLoading: true
    })

    try {
      var ext = event.target.files[0].name.split('.').pop()

      if (ext !== '') {
        if (
          ext === 'png' ||
          ext === 'jpg' ||
          ext === 'jpeg' ||
          ext === 'mp3' ||
          ext === 'pdf'
        ) {
          if (event.target.files[0].size > 1048576) {
            alert('Se solicita un archivo no mayor a 1MB. Por favor verifica.')
            // $('#group_image').val('')
          } else {
            if (event.target.files && event.target.files[0]) {
              let reader = new FileReader()
              reader.onload = (e) => {
                this.setState({
                  url: e.target.result
                })
              }
              reader.readAsDataURL(event.target.files[0])
              this.uploadImageChat(event)
            }
          }
        } else {
          // $('#group_image').val('')
          alert('Extensión no permitida: ' + ext)
        }
      }
    } catch (error) {
      this.setState({
        isLoading: false
      })
      console.error(error)
    }
  }

  //MARK : - Action
  uploadImageChat = (event) => {
    const file = event.target.files[0]
    var ext = event.target.files[0].name.split('.').pop()

    var route = 'chats'
    var routeName = `${Date.now()}.${ext}`

    const storageRef = storage.ref(`${route}/${routeName}`)
    const task = storageRef.put(file)

    task.on(
      'state_changed',
      (snapshot) => {
        //loading
      },
      (error) => {
        console.error(error.message)
        this.setState({
          isLoading: false
        })
      },
      async () => {
        var downloadURL = await task.snapshot.ref.getDownloadURL()
        this.props.onClick(1, downloadURL, 0)

        this.setState({
          isLoading: false,
          type: 0
        })
      }
    )
  }
}
