import React from 'react'
import styles from '../../styles.module.css'
import { Container, Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { AdviserTypes } from '../../reducers/adviserRedux'
import { MessagesTypes } from '../../reducers/messageRedux'
import { Footer } from '../home/footer'
import ScreenMessage from './screenMessage'

class MessageView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      age: '',
      name: '',
      activeFilter: false,
      office: {},
      inputTxtChat: ''
    }
  }

  componentDidMount() {
    var adviser = this.props.adviserRedux.adviserSelect
    this.props.getMessages(adviser)
  }

  render() {
    return <div className={styles.containerHome}>{this.body()}</div>
  }

  changeTxtChat = (e) => {
    this.setState({
      inputTxtChat: e.target.value
    })
  }

  body() {
    var userSelect = this.props.adviserRedux.adviserSelect
    var messageList = this.props.messageRedux.messageList
    return (
      <div className={styles.homeBody}>
        <div className={styles.homeBodyContainer}>
          <ScreenMessage
            user={this.props.userRedux.user}
            activeChat={true}
            adviser={userSelect}
            message={messageList}
            height={null}
            onChange={this.changeTxtChat}
            value={this.state.inputTxtChat}
            onClick={(type, url, durationAudio) => {
              this.props.saveMessage({
                userSelect: userSelect,
                message: this.state.inputTxtChat,
                type: type,
                url: url,
                durationAudio: durationAudio
              })
              this.setState({ inputTxtChat: '' })
            }}
            onClickBack={() => this.props.onClickBack()}
          />
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  userRedux: state.UserRedux,
  adviserRedux: state.AdviserRedux,
  categoryRedux: state.CategoryRedux,
  panelRedux: state.PanelRedux,
  locationRedux: state.LocationRedux,
  messageRedux: state.MessageRedux
})
const mapDispatchToProps = (dispatch) => ({
  requestAdviserByCategory: (data) => {
    dispatch({ type: AdviserTypes.REQUEST_ADVISER_BY_CATEGORY, data: data })
  },
  getMessages: (data) => {
    dispatch({
      type: MessagesTypes.REQUEST_MESSAGE,
      data: data
    })
  },
  saveMessage: (data) => {
    dispatch({
      type: MessagesTypes.SAVE_MESSAGE,
      data: data
    })
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(MessageView)
