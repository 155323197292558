import React from 'react'
import { Provider } from 'react-redux'
import 'bootstrap/dist/css/bootstrap.min.css'

import { PersistGate } from 'redux-persist/integration/react'
import configureStore from './configureStore'
import VideoCall from './components/video/video'

console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed']
console.disableYellowBox = true

const { store, persistor } = configureStore()
import ContainerMain from './components/containerMain'
import { auth, db } from './fireStoreConfig'

export const IkowButton = ({ apiKey, color, nameBusiness }) => {
  if (apiKey === null || apiKey === undefined) {
    console.error('Falta el ApiKey')
    auth.signOut()
  }
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ContainerMain
          apiKey={apiKey}
          color={color}
          nameBusiness={nameBusiness}
        ></ContainerMain>
        {<VideoCall></VideoCall>}
      </PersistGate>
    </Provider>
  )
}
