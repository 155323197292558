import React from 'react'
import styles from '../styles.module.css'
import Login from './login/login'
import Home from './home/home'
import { connect } from 'react-redux'
import FadeIn from 'react-fade-in'

class ContainerInfo extends React.Component {
  render() {
    const { user } = this.props.userRedux
    var isLogin = user != null
    return (
      <div className={styles.containerBox}>
        <div className={styles.containerInfo}>
          {isLogin ? <Home></Home> : <Login></Login>}
        </div>

        <div
          style={{
            flexDirection: 'row',
            flex: '1'
          }}
        ></div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  userRedux: state.UserRedux
})
const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ContainerInfo)
