import React from 'react'
import { Container, Row } from 'reactstrap'
import styles from './styles.css'

// import { bindActionCreators } from "redux";
import { connect } from 'react-redux'
let interval = null
class CounterTime extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      timeSecond: 0
    }
  }

  componentDidMount() {
    interval = setInterval(() => {
      this.setState({
        timeSecond: this.state.timeSecond + 1
      })
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(interval)
  }

  render() {
    return <div className={styles.counter}  >
        <span className={styles.fs16} >Tiempo de llamada</span><br />
        <span className={styles.fs50}  >{`${this.convertToHourFormat(this.state.timeSecond)}`}</span>
      </div>
  }

  convertToHourFormat(second) {
    var sec_num = parseInt(second, 0);
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes+':'+seconds;
}
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps)(CounterTime)
