import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'

import { reducer as VideoCallRedux } from './videoCallRedux'
import { reducer as UserRedux } from './userRedux'
import { reducer as PanelRedux } from './panelRedux'
import { reducer as AdviserRedux } from './adviserRedux'
import { reducer as CategoryRedux } from './categoryRedux'
import { reducer as LocationRedux } from './locationRedux'
import { reducer as MessageRedux } from './messageRedux'
import { reducer as SessionRedux } from './sesionRedux'

const reducers = combineReducers({
  VideoCallRedux,
  UserRedux,
  PanelRedux,
  AdviserRedux,
  CategoryRedux,
  LocationRedux,
  MessageRedux,
  SessionRedux
})

export default reducers
