import React from 'react'
import styles from '../../styles.module.css'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Footer } from '../home/footer'
import ButtonRedes from './buttonRedes'
import TextField from '@mui/material/TextField'
import { InputIkow } from '../inputIkow'

class Login extends React.Component {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 5,
          height: '100%',
          backgroundColor: 'white',
          borderRadius: '20px'
        }}
      >
        {this.header()}
        {this.formLogin()}
      </div>
    )
  }

  header() {
    const { color, nameBusiness } = this.props.panelRedux
    return (
      <div className={styles.loginHeader} style={{ backgroundColor: color }}>
        <Container>
          <Row>
            <Col xs={12}>
              <h1>{'Servicio al cliente'}</h1>
            </Col>
            <Col>
              <p>{`En ${nameBusiness} podras consultar por chat o videollamadas tus dudas.`}</p>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  formLogin() {
    const { color } = this.props.panelRedux
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1
        }}
      >
        <div
          style={{
            flex: 5,
            margin: '10px',
            padding: '5px 20px'
          }}
        >
          {/* <Form>
            <InputIkow />
          </Form> */}
          <div>
            <hr className='hr-text' data-content='OR'></hr>
            <ButtonRedes></ButtonRedes>
            <hr />
            <label className={styles.label}>
              <b>Nota: </b>
              {
                'Si inicias sesion de manera anonima, no podras compartir o subir archivos.'
              }
            </label>
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  panelRedux: state.PanelRedux
})
const mapDispatchToProps = (dispatch) => ({
  // loginAnonymous: (data) => {
  //   dispatch({ type: UserTypes.REQUEST_USER, data: data })
  // }
})
export default connect(mapStateToProps, mapDispatchToProps)(Login)
