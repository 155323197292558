import { put, call, select } from 'redux-saga/effects'
import locationRedux from '../reducers/locationRedux'
// import getCountryJson from '../select_city/country'
// import getStateJson from '../select_city/states'
import { db } from '../fireStoreConfig'
import { helpers } from '../helpers'
// import getCitiesJson from '../select_city/city'

const getPanelStates = (state) => state.PanelRedux

export function* getCountries(action) {
  // const { receivedCountry } = locationRedux
  // var dataArray = getCountryJson()
  // yield put(receivedCountry(dataArray))
}

export function* getStateCountry(action) {
  // const { receivedState } = locationRedux
  // var country = action.data
  // var statesList = getStateJson()
  // var data = statesList.filter(function (statesCountry) {
  //   return Number(statesCountry.id_country) === Number(country.id)
  // })
  // yield put(receivedState(data))
}

export function* getCitiesWithIdState(action) {
  const { receivedCities } = locationRedux

  var cities = yield call(function () {
    return new Promise(function (resolve, reject) {
      var headers = new Headers()
      headers.append(
        'X-CSCAPI-KEY',
        'TjNmWVJURVVOT0I1MVNVMnhKTkpKZWl5cXFGVmVZMVp4eVdqSkpGcQ=='
      )

      var requestOptions = {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
      }

      fetch(
        'https://api.countrystatecity.in/v1/countries/CO/cities',
        requestOptions
      )
        .then((response) => resolve(response.json()))
        .then((result) => {})
        .catch((error) => console.error('error', error))
    })
  })

  if (cities !== null && cities !== undefined) {
    yield put(receivedCities(cities))
  }
}

export function* getOffices(action) {
  const panelRedux = yield select(getPanelStates)
  const { receivedOffices } = locationRedux

  var offices = yield call(function () {
    return new Promise(function (resolve, reject) {
      var officeList = []
      db.collection('enviroment/prod/business')
        .doc(panelRedux.apiKey)
        .collection('offices')
        .limit(20)
        .get()
        .then((querySnapshot) => {
          officeList = helpers.SnapShopToArrayWithId(querySnapshot)
          resolve(officeList)
        })
    })
  })

  yield put(receivedOffices(offices ?? []))
}
