import React from 'react'
import logo from '/../../assets/logoColor.png'

export class Footer extends React.Component {
  render() {
    return (
      <a href={'https://ikowapp.com/'} target='_blank'>
        <div
          style={{
            textAlign: 'center',
            paddingBottom: '10px',
            fontSize: '12px',
            cursor: 'pointer'
          }}
        >
          {'  Power by  '}
          <img src={logo} width={'40px'}></img>
        </div>
      </a>
    )
  }
}
