import { db } from './fireStoreConfig'

export const helpers = {
  jsonToArrayWithId: (json) => {
    if (!json) {
      return []
    }
    let array = Object.keys(json).map(function (key) {
      json[key].unique_elem_id = key
      return json[key]
    })
    return array
  },

  SnapShopToArrayWithId: (querySnapshot) => {
    const data = querySnapshot.docs.map((doc) => {
      var document = doc.data()
      document['uid'] = doc.id
      return document
    })

    if (!data) {
      return []
    }
    let array = Object.keys(data).map(function (key) {
      data[key].unique_elem_id = key
      return data[key]
    })

    var dataTest = data != null ? array : []
    const filterResult = dataTest.filter((data) => data.uid !== '--stats--')
    return filterResult
  },
  getUserByUid: async (uid) => {
    var userDoc = await db
      .collection(`enviroment/prod/users`)
      .where('uid', '==', uid)
      .get()

    var user = userDoc.docs.map((doc) => {
      return doc.data()
    })

    return user[0]
  }
}
