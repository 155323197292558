import React from 'react'
import styles from '../styles.module.css'
import { ReactComponent as Cancelar } from '../assets/cancelar.svg'
import videoIcon from '/../assets/videoIconButton.png'

export class FloatingButton extends React.Component {
  render() {
    return (
      <div>
        <div
          id='floatingButton'
          className={(styles.test, styles.dot)}
          style={{ backgroundColor: this.props.color, transition: '0.5s' }}
          onClick={() => this.props.onClick()}
        >
          {this.props.isOpenContainer ? (
            <Cancelar fill={'white'} height={'15px'} />
          ) : (
            <img src={videoIcon} width={'30px'} height={'30px'} />
          )}
        </div>
      </div>
    )
  }
}
