import React from 'react'
import { Row } from 'react-bootstrap'
import ScreenIncomingCall from './components/ScreenIncomingCall'
import Call from './components/Call'
import { Container } from 'reactstrap'
import styles from '../../styles.module.css'
import classNames from 'classnames'

// import { bindActionCreators } from "redux";
import { connect } from 'react-redux'

class VideoCall extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeMaxWindow: true
    }
  }

  componentDidMount() {}

  render() {
    // var messageCall = sessionStorage.getItem("statusMessageLlamada");
    const { callReducer, userReducer } = this.props
    const colorBackground = callReducer.isCalling
      ? styles.bgGray
      : styles.bgBlack
    const colorInCallBackground = callReducer.inCalling
      ? styles.bgGray
      : styles.bgBlack
    return (
      <div>
        {(callReducer.isCalling || callReducer.inCalling) && (
          <div
            className={
              this.state.activeMaxWindow
                ? classNames(styles.styleFull, colorInCallBackground)
                : styles.styleSmall
            }
          >
            <Container
              fluid
              className={classNames(
                styles.videoContainer,
                colorBackground,
                'main-content-container px-12'
              )}
            >
              {callReducer.isCalling && (
                <ScreenIncomingCall
                  user={userReducer.user}
                  emissorUid={callReducer.emissorUid}
                  receptorUid={callReducer.receptorUid}
                />
              )}
              {callReducer.inCalling && (
                <Call
                  channel={callReducer.channel}
                  emissorUid={callReducer.emissorUid}
                  receptorUid={callReducer.receptorUid}
                  onClick={() => {
                    var data = !this.state.activeMaxWindow
                    this.setState({
                      activeMaxWindow: data
                    })
                  }}
                />
              )}
            </Container>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  callReducer: state.VideoCallRedux.videoCall,
  userReducer: state.UserRedux
})

export default connect(mapStateToProps)(VideoCall)
