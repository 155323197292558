import { put, take, select, call } from 'redux-saga/effects'
import categoryRedux from '../reducers/categoryRedux'
import { auth, db } from '../fireStoreConfig'

const getCategoryReducer = (state) => state.CategoryRedux
const getPanelStates = (state) => state.PanelRedux

export function* getCategoriesAll() {
  const { receivedCategories } = categoryRedux
  var allCategory = yield call(function () {
    return new Promise(function (resolve, reject) {
      db.collection(`enviroment/prod/category`)
        .get()
        .then((querySnapshot) => {
          const dataTest = querySnapshot.docs.map((doc) => {
            var data = doc.data()
            data.uid = doc.id
            return data
          })
          resolve(dataTest)
        })
        .catch((error) => {
          console.error(error)
        })
    })
  })
  yield put(receivedCategories(allCategory))
}

export function* getCategoriesAllBusiness() {
  const { receivedCategoriesBusiness } = categoryRedux

  const panelRedux = yield select(getPanelStates)

  const categoryReducer = yield select(getCategoryReducer)

  const categoryList = categoryReducer.categoryList

  var categoryListClone = [...categoryList]

  var categoryListBusiness = []

  var categoriesActive = yield call(function () {
    return new Promise(function (resolve, reject) {
      db.collection(`enviroment/prod/business`)
        .doc(panelRedux.apiKey)
        .collection('categories')
        .get()
        .then((querySnapshot) => {
          const dataTest = querySnapshot.docs.map((doc) => {
            var data = doc.data()
            data.uid = doc.id
            return data
          })
          resolve(dataTest)
        })

        .catch((error) => {
          console.error(error)
        })
    })
  })

  categoriesActive.forEach((element) => {
    var category = categoryListClone.find((clone) => clone.uid == element.uid)
    if (category != undefined && category != -1) {
      var data = { ...category, active: element.active }
      categoryListBusiness.push(data)
    }
  })

  yield put(receivedCategoriesBusiness(categoryListBusiness))
}
