import React from 'react'
import styles from '../../styles.module.css'
import { Container, Row, Col } from 'react-bootstrap'
import helperCall from '../../helperCall'
import MessageIcon from '@material-ui/icons/Message'
import PersonalVideoIcon from '@material-ui/icons/PersonalVideo'

export class CardAdviser extends React.Component {
  render() {
    const isOnline = this.props.adviser.isOnline
    return (
      <Container
        key={this.props.index}
        style={{
          backgroundColor: 'white',
          paddingTop: '10px',
          paddingBottom: '10px',
          borderRadius: '35px',
          marginTop: '10px',
          boxShadow: '2px 2px 9px 0px rgba(0, 0, 0, 0.05)'
        }}
      >
        <Row>
          <Col xs={2}>
            <img
              src={this.props.adviser.urlPhoto}
              alt='new'
              width='50px'
              height='50px'
              style={{ borderRadius: '50%' }}
            />{' '}
          </Col>
          <Col xs={6}>
            <Container>
              <Row style={{ fontWeight: 'bold', fontSize: '12px' }}>
                {this.props.adviser.nickName}
              </Row>
              <Row
                style={{
                  color: isOnline ? '#5cce38' : 'red',
                  fontSize: '14px'
                }}
              >
                {isOnline ? `online` : `offLine`}
              </Row>
            </Container>
          </Col>
          <Col xs={4}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  height: '35px',
                  width: '35px',
                  backgroundColor: '#5CCE38',
                  cursor: 'pointer',
                  opacity: isOnline ? 1 : 0.5
                }}
                onClick={() => {
                  //validacion si esta ocupado
                  if (isOnline) {
                    helperCall.callingUpdate(
                      this.props.user.uid,
                      this.props.adviser.uid,
                      true,
                      false,
                      false
                    )
                    helperCall.pushCall(this.props.user, this.props.adviser)
                  }
                }}
              >
                <PersonalVideoIcon style={{ color: 'white' }} />
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  height: '35px',
                  width: '35px',
                  backgroundColor: '#5CCE38',
                  cursor: 'pointer',
                  opacity: 1
                }}
                onClick={() => this.props.onclickMessage()}
              >
                <MessageIcon style={{ color: 'white' }} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}
