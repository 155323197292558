import { put, call, select, take, fork } from 'redux-saga/effects'
import { auth, db } from '../fireStoreConfig'
import { eventChannel } from 'redux-saga'
import VideoCallRedux from '../reducers/videoCallRedux'

export function* sendVideoCall(action) {
  console.info('sendVideoCall')
}

export function* videoCallListener(uid) {
  const { setVideoCall } = VideoCallRedux
  try {
    const channel = new eventChannel((emiter) => {
      const listener = db
        .collection('enviroment/prod/users')
        .doc(uid)
        .collection('call')
        .doc(uid)
        .onSnapshot((doc) => {
          var data = []
          data = doc.data()
          var dataCall = {
            channel: data['channel'],
            emissorUid: data['emmisor'],
            receptorUid: data['receptor'],
            isCalling: data['isCalling'],
            inCalling: data['inCalling'],
            hangup: data['hangup']
          }

          emiter({
            data: dataCall
          })
        })
      return () => {
        listener.on()
      }
    })
    while (true) {
      const { data } = yield take(channel)
      yield put(setVideoCall(data))
    }
  } catch (error) {
    console.error(error)
  }
}
