import React, { Component } from 'react'
import moment from 'moment'

import play from '/../../assets/play.png'
import pause from '/../../assets/pause.png'
import playWhite from '/../../assets/playWhite.png'
import pauseWhite from '/../../assets/pauseWhite.png'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'

import styles from './../../styles.module.css'

export default class CardMessage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dataSound: '',
      isPlay: false
    }
  }

  render() {
    const { index, data } = this.props
    var isRight = this.props.isRight

    return (
      <div
        key={this.props.index}
        className={
          isRight ? styles.containerMessageRight : styles.containerMessageLeft
        }
      >
        {isRight && this.hour(data)}
        {!isRight && this.avatar(data)}
        {this.setDataType(data, isRight)}
        {!isRight && this.hour(data)}
        {isRight && this.avatar(data)}
        {}
      </div>
    )
  }

  avatar(data) {
    const { user, userSelect } = this.props
    var isUser = data.idFrom == this.props.user.uid
    return (
      <div style={{ margin: '0px 5px' }}>
        <img
          src={isUser ? user.urlPhoto : userSelect.urlPhoto}
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            imageRendering: 'crisp-edges'
          }}
        />
      </div>
    )
  }

  setDataType(data, isRight) {
    switch (data.type) {
      case 0:
        return (
          <div className={isRight ? styles.messageRight : styles.messageLeft}>
            <p>{data.content}</p>
          </div>
        )

      case 1:
        return (
          <div
            className={isRight ? styles.messageRight : styles.messageLeft}
            style={{
              width: '40%'
            }}
          >
            {data.url.includes('pdf') ? (
              <div
                style={{
                  cursor: 'pointer'
                }}
                onClick={() => {
                  window.open(data.url)
                }}
              >
                <PictureAsPdfIcon />
                <span>ver documento</span>
              </div>
            ) : (
              <img src={data.url} alt='new' />
            )}
          </div>
        )

      case 2:
        var playColor = isRight ? playWhite : play
        var pauseColor = isRight ? pauseWhite : pause
        return (
          <div
            className={isRight ? styles.messageRight : styles.messageLeft}
            onClick={async () => {
              var dataSound = await getWebFileArrayBuffer(data.url)
              this.props.player.feed(dataSound)
            }}
            style={{ width: '20%' }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingLeft: '16px',
                paddingRight: '16px'
              }}
            >
              <div>
                <img
                  onClick={async () => {
                    var dataSound = await getWebFileArrayBuffer(data.url)
                    this.props.player.feed(dataSound)
                    this.setState({
                      isPlay: true
                    })
                  }}
                  src={this.state.isPlay ? pauseColor : playColor}
                  width={10}
                ></img>
              </div>
              <div></div>
              <div>{'00:00'}</div>
            </div>
          </div>
        )

      default:
        return (
          <div
            style={{
              width: '60%',
              backgroundColor: isRight ? '#0095ff' : '#E8E8E8',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '10px',
              padding: '1%',
              marginTop: '5px'
            }}
          >
            {data.content}
          </div>
        )
    }
  }

  hour(data) {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div></div>
        <div
          style={{ fontSize: '10px', padding: '0px 20px', color: '#a9a9b4' }}
        >
          {moment(data.timestamp.seconds * 1000).format('HH:mm:ss')}
        </div>
      </div>
    )
  }
}

const getWebFileArrayBuffer = async (url) => {
  return await fetch(url).then((response) => response.arrayBuffer())
}
