import { put, call, take, fork, delay } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { auth, db } from '../fireStoreConfig'
import userRedux from '../reducers/userRedux'
import { videoCallListener } from './videoCallSagas'
import { getAdviserByIdBusiness } from './adviserSagas'
import { getCategoriesAll, getCategoriesAllBusiness } from './categorySagas'
import firebase from 'firebase/app'

const REACT_APP_FIREBASE = 'enviroment/prod'
const UID_BUSINESS = 'LyHpWP5BglNp0lTdK0SXZwPJO4t1'
const REACT_APP_UID_ADMIN = 'Fl4RE1fsvDciyWJXg8JcrTeQKXJ2'
const PHOTO_URL =
  'https://firebasestorage.googleapis.com/v0/b/your-psychologist.appspot.com/o/profile%2Fuser-default.jpg?alt=media&token=557a66e7-a168-4b06-9bef-8f5719abf1a1'

var nickNameSelect = ''
var email = ''

export function* loginAnonymous(action) {
  nickNameSelect = action.data.nickName
  email = 'sramos@ikowapp.com'

  auth.signInAnonymously().catch(function (error) {
    // Handle Errors here.
    var errorCode = error.code
    var errorMessage = error.message
    console.error(errorMessage)
    // ...
  })
}

export function* registerUserWhenNoExists(user) {
  //this method only use when no exists in DB
  const { setUser } = userRedux
  var createdAccount = yield call(function () {
    return new Promise(function (resolve, reject) {
      const registerUsers = {
        uid: user.uid,
        cedula: '',
        city: '451042',
        countryCode: 'CO',
        created: firebase.firestore.FieldValue.serverTimestamp(),
        email: user.email,
        haveCallFree: false,
        haveCreditCard: false,
        isOnline: true,
        nickName: user.displayName,
        name: user.displayName.toLowerCase(),
        phone: '',
        state: '1710',
        isActive: true,
        token: '',
        country: '82',
        urlPhoto: user.photoURL,
        rol: ['User'],
        categories: [''],
        business: ['LyHpWP5BglNp0lTdK0SXZwPJO4t1'],
        businessClient: [],
        createdFrom: 'Ikow button'
      }

      db.collection(`${REACT_APP_FIREBASE}/users`)
        .doc(user.uid)
        .set(registerUsers)
        .then(() => {
          db.collection(`${REACT_APP_FIREBASE}/users`)
            .doc(user.uid)
            .collection(`call`)
            .doc(user.uid)
            .set({
              emmisor: user.uid,
              hangup: false,
              inCalling: false,
              isCalling: false,
              receptor: user.uid,
              status: 'free'
            })
            .then(() => {
              db.collection(`${REACT_APP_FIREBASE}/users`)
                .doc(user.uid)
                .collection(`notificationChat`)
                .doc(user.uid)
                .set({
                  bagde: true,
                  count: 1,
                  uid: user.uid
                })
                .then(() => {
                  db.collection(`${REACT_APP_FIREBASE}/users`)
                    .doc(user.uid)
                    .collection(`tutorial`)
                    .doc(user.uid)
                    .set({
                      home: true,
                      profile: true,
                      timeCall: 0
                    })
                    .then(() => {
                      db.collection(`${REACT_APP_FIREBASE}/business`)
                        .doc(UID_BUSINESS)
                        .collection(`client`)
                        .doc(user.uid)
                        .set({
                          email: user.email,
                          uid: user.uid
                        })
                        .then(() => {
                          //Registrar indicadores en el admin
                          var uidAdmin = REACT_APP_UID_ADMIN
                          db.collection(`${REACT_APP_FIREBASE}/users`)
                            .doc(uidAdmin)
                            .collection('indicators')
                            .doc(uidAdmin)
                            .get()
                            .then((doc) => {
                              var newValue = parseInt(doc.data().users + 1)
                              db.collection(`${REACT_APP_FIREBASE}/users`)
                                .doc(uidAdmin)
                                .collection('indicators')
                                .doc(uidAdmin)
                                .update({ users: newValue })
                                .then(() => {
                                  db.collection(`${REACT_APP_FIREBASE}/users`)
                                    .doc(user.uid)
                                    .get()
                                    .then((doc) => {
                                      resolve(doc.data())
                                    })
                                })
                                .catch((error) => {
                                  console.error(error)
                                  resolve(null)
                                })
                            })
                        })
                    })
                })
            })
            .catch(function (e) {
              console.error(e + 'Error al crear el usuario')
              resolve(false)
            })
        })
    })
  })
  if ((createdAccount !== null) & (createdAccount !== undefined)) {
    yield put(setUser(createdAccount))
    yield fork(videoCallListener, user.uid)
    yield delay(500)
    yield fork(getCategoriesAll)
    yield delay(500)
    yield fork(getAdviserByIdBusiness)
    yield delay(500)
    yield fork(getCategoriesAllBusiness)
  }
}

export function* userListenerAuth() {
  const { setUser } = userRedux

  try {
    const channel = new eventChannel((emiter) => {
      const listener = auth.onAuthStateChanged(function (user) {
        if (user) {
          var isAnonymous = user.isAnonymous

          if (isAnonymous) {
            user
              .updateProfile({
                displayName: 'Anonymous',
                photoURL: PHOTO_URL
              })
              .then((data) => {
                emiter({
                  userFirebase: user
                })
              })
          } else {
            emiter({
              userFirebase: user
            })
          }
        } else {
          emiter({
            userFirebase: null
          })
        }
      })
      return () => {
        listener.on()
      }
    })
    while (true) {
      const { userFirebase } = yield take(channel)

      if (userFirebase !== null && userFirebase !== undefined) {
        yield fork(validateUser, userFirebase)
      } else {
        yield put(setUser(null))
      }
    }
  } catch (error) {
    yield put(setUser(null))
    console.error(error)
  }
}

export function* logOut(action) {
  yield call(function () {
    return new Promise(function (resolve, reject) {
      auth.signOut()
    })
  })
}

export function* loginWithRedes(action) {
  yield call(function () {
    return new Promise(function (resolve, reject) {
      var provider = null
      if (action.data === 'facebook') {
        var provider = new firebase.auth.FacebookAuthProvider()
      } else if (action.data === 'google') {
        var provider = new firebase.auth.GoogleAuthProvider()
      } else {
        console.error(`must select (google,facebook,apple)`)
        return
      }

      auth
        .signInWithPopup(provider)
        .then((result) => {
          var credential = result.credential
          var user = result.user
          var accessToken = credential.accessToken
          resolve(credential)
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          var errorCode = error.code
          var errorMessage = error.message
          var email = error.email
          var credential = error.credential
          console.error(error)
          swal('', `${email}, ${errorMessage}`, 'error').then((value) => {
            swal.close()
          })
          resolve(null)
        })
    })
  })
}

export function* validateUser(user) {
  const { setUser } = userRedux

  try {
    var currentUser = yield call(function () {
      return new Promise(function (resolve, reject) {
        db.collection('enviroment/prod/users')
          .doc(user.uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              resolve(doc.data())
            } else {
              resolve(null)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    })

    if (currentUser !== null && currentUser !== undefined) {
      yield put(setUser(currentUser))
      yield fork(videoCallListener, currentUser.uid)
      yield delay(500)
      yield fork(getCategoriesAll)
      yield delay(500)
      yield fork(getAdviserByIdBusiness)
      yield delay(500)
      yield fork(getCategoriesAllBusiness)
    } else {
      yield fork(registerUserWhenNoExists, user)
    }
  } catch (error) {
    console.error(error)
  }
}

// export function* createAccountDB(uid) {
//   var createAccount = yield call(function () {
//     return new Promise(function (resolve, reject) {
//       db.collection('enviroment/prod/users')
//         .doc(uid)
//         .set({
//           uid: uid,
//           nickName: nickNameSelect
//         })
//         .then(() => {
//           resolve(true)
//         })
//     })
//   })

//   if (createAccount) {
//     var createCall = yield call(function () {
//       return new Promise(function (resolve, reject) {
//         db.collection('enviroment/prod/users')
//           .doc(uid)
//           .collection('call')
//           .doc(uid)
//           .set({
//             emmisor: uid,
//             hangup: false,
//             inCalling: false,
//             isCalling: false,
//             receptor: uid,
//             status: 'free'
//           })
//           .then(() => {
//             resolve(true)
//           })
//       })
//     })
//   }

//   if (createCall) {
//     yield fork(videoCallListener, uid)
//     yield delay(500)
//     yield fork(getCategoriesAll)
//     yield delay(500)
//     yield fork(getAdviserByIdBusiness)
//     yield delay(500)
//     yield fork(getCategoriesAllBusiness)
//   }
// }
