import React from 'react'
import styles from '../styles.module.css'
import { connect } from 'react-redux'
import { FloatingButton } from './button'
import ContainerInfo from './containerInfo'
import { PanelTypes } from '../reducers/panelRedux'

class ContainerMain extends React.Component {
  constructor(props) {
    super(props)
    const { isOpenContainer } = this.props.panelRedux
    var data = {
      apiKey: this.props.apiKey,
      color: this.props.color,
      nameBusiness: this.props.nameBusiness
    }
    this.props.setApiKey(data)

    if (window && window.parent) {
      console.log(isOpenContainer)
      window.parent.postMessage({ isOpenContainer: isOpenContainer }, '*')
    }
  }
  render() {
    const { isOpenContainer } = this.props.panelRedux
    const { color } = this.props.panelRedux
    return (
      <div className={styles.containerMain}>
        {isOpenContainer && <ContainerInfo></ContainerInfo>}
        <FloatingButton
          text={this.props.text}
          isOpenContainer={isOpenContainer}
          color={color}
          onClick={() => {
            this.props.actionButton(isOpenContainer) // is normal because send in redux invert
            //is necesary by use with iframe
            if (window && window.parent) {
              window.parent.postMessage(
                { isOpenContainer: !isOpenContainer },
                '*'
              )
            }
          }}
        ></FloatingButton>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  panelRedux: state.PanelRedux
})
const mapDispatchToProps = (dispatch) => ({
  actionButton: (data) => {
    dispatch({ type: PanelTypes.ACTION_CONTAINER_INFO, data: data })
  },
  setApiKey: (data) => {
    dispatch({ type: PanelTypes.SET_API_KEY, data: data })
  },
  setProps: (data) => {
    dispatch({ type: PanelTypes.SET_PROPS, data: data })
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(ContainerMain)
