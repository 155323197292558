import { put, select, call } from 'redux-saga/effects'
import { db } from '../fireStoreConfig'

import { helpers } from '../helpers'
import firebase from 'firebase/app'
import sessionRedux from '../reducers/sesionRedux'

const getCategoryReducer = (state) => state.categoryRedux
const getBusinessReducer = (state) => state.businessRedux

export function* getSessionIndicator(action) {
  let businessRedux = yield select(getBusinessReducer)
  const business = businessRedux.business
  var limit = 100 //action.data.limit;

  const { receivedSessionIndicator } = sessionRedux
  var dataQuery = yield call(function () {
    return new Promise(function (resolve, reject) {
      db.collection(`enviroment/prod/business`)
        .doc(business.id)
        .collection('session')
        .orderBy('time', 'desc')
        .limit(limit)
        .get()
        .then((querySnapshotData) => {
          const data = querySnapshotData.docs.map((doc) => doc.data())
          var dataList = data !== null ? helpers.jsonToArrayWithId(data) : []
          resolve(dataList)
        })
        .catch((error) => {
          console.error(error)
        })
    })
  })

  dataQuery.sort(function (a, b) {
    return b.time.seconds - a.time.seconds ? 1 : -1
  })

  yield put(receivedSessionIndicator(dataQuery))
}

export function* getSessionByAdviser(action) {
  var limit = 20 //action.data.limit;

  const { receivedSessionByAdviser } = sessionRedux
  var dataQuery = yield call(function () {
    return new Promise(function (resolve, reject) {
      db.collection(`enviroment/prod/users`)
        .doc(action.data.uid)
        .collection('session')
        .orderBy('time', 'desc')
        .limit(limit)
        .get()
        .then((querySnapshotData) => {
          const dataTest = querySnapshotData.docs.map((doc) => {
            return doc.data()
          })
          var dataList = helpers.jsonToArrayWithId(dataTest)
          resolve(dataList)
        })

        .catch((error) => {
          console.error(error)
        })
    })
  })

  dataQuery.sort(function (a, b) {
    return b.time.seconds - a.time.seconds ? 1 : -1
  })

  yield put(receivedSessionByAdviser(dataQuery))
}

export function* getSessionByUser(action) {
  var limit = 20 //action.data.limit;

  const { receivedSessionByUser } = sessionRedux
  var dataQuery = yield call(function () {
    return new Promise(function (resolve, reject) {
      db.collection(`enviroment/prod/users`)
        .doc(action.data.uid)
        .collection('session')
        .orderBy('time', 'desc')
        .limit(limit)
        .get()
        .then((querySnapshotData) => {
          const dataTest = querySnapshotData.docs.map((doc) => {
            return doc.data()
          })
          var dataList = helpers.jsonToArrayWithId(dataTest)
          resolve(dataList)
        })

        .catch((error) => {
          console.error(error)
        })
    })
  })

  dataQuery.sort(function (a, b) {
    return b.time.seconds - a.time.seconds ? 1 : -1
  })

  yield put(receivedSessionByUser(dataQuery))
}

export function* saveSession(action) {
  var timeSecond = action.data.timeSecond
  timeSecond = `${timeSecond} Seg`
  var dateCall = firebase.firestore.Timestamp.fromDate(new Date())
  var validateUserBusiness = action.data.timeSecond > 30

  /*Inicio Obtener datos de emisor */
  var emisorCall = yield call(function () {
    return new Promise(function (resolve, reject) {
      db.collection(`enviroment/prod/users`)
        .doc(action.data.emissorUid)
        .get()
        .then((doc) => {
          const dataTest = doc.data()
          resolve(dataTest)
        })
        .catch((error) => {
          console.error(error)
        })
    })
  })

  /*Inicio Obtener datos de Receptor */
  var receptorCall = yield call(function () {
    return new Promise(function (resolve, reject) {
      db.collection(`enviroment/prod/users`)
        .doc(action.data.receptorUid)
        .get()
        .then((doc) => {
          const dataTest = doc.data()
          resolve(dataTest)
        })
        .catch((error) => {
          console.error(error)
        })
    })
  })

  /*Validación despues de 30 segundos agregar cliente */
  if (validateUserBusiness) {
    saveUserInBusiness(emisorCall, receptorCall)
  }

  var sessionCallBussines = {}
  var uidBussines = ''

  if (
    (emisorCall['rol'].includes('Bussiness_User') &&
      receptorCall['rol'].includes('Bussiness_User')) ||
    (emisorCall['rol'].includes('User') &&
      receptorCall['rol'].includes('Bussiness_User'))
  ) {
    sessionCallBussines = {
      nameEmisor: emisorCall['nickName'],
      nameReceptor: receptorCall['nickName'],
      time: dateCall,
      uidCategory: receptorCall['categories'][0],
      uidEmisor: emisorCall['uid'],
      uidReceptor: receptorCall['uid']
    }
    uidBussines = receptorCall['business'][0].toString()
  } else if (
    emisorCall['rol'].includes('Bussiness_User') &&
    receptorCall['rol'].includes('User')
  ) {
    sessionCallBussines = {
      nameEmisor: emisorCall['nickName'],
      nameReceptor: receptorCall['nickName'],
      time: dateCall,
      uidCategory: emisorCall['categories'][0],
      uidEmisor: emisorCall['uid'],
      uidReceptor: receptorCall['uid']
    }
    uidBussines = emisorCall['business'][0].toString()
  }

  /*Inicio Modelo Session de Receptor */
  var sessionCallReceptor = {
    chargeSesionPrice: false,
    description: timeSecond,
    name: emisorCall['nickName'],
    time: dateCall,
    uid: emisorCall['uid'],
    uidClient: emisorCall['uid'],
    uidPsychologist: receptorCall['uid'],
    urlPhoto: emisorCall['urlPhoto']
  }

  /*Inicio Modelo Session de Emisior */
  var sessionCallEmisor = {
    chargeSesionPrice: false,
    description: timeSecond,
    name: receptorCall['nickName'],
    time: dateCall,
    uid: receptorCall['uid'],
    uidClient: emisorCall['uid'],
    uidPsychologist: receptorCall['uid'],
    urlPhoto: receptorCall['urlPhoto']
  }

  const increment = firebase.firestore.FieldValue.increment(1)

  const statsRef = db
    .collection(`enviroment/prod/business`)
    .doc(uidBussines)
    .collection('sessions')
    .doc('--stats--')

  const sessionRef = db
    .collection(`enviroment/prod/business`)
    .doc(uidBussines)
    .collection('sessions')
    .doc()

  const batch = db.batch()

  batch.set(sessionRef, sessionCallBussines)
  batch.set(statsRef, { sessionCount: increment }, { merge: true })
  batch
    .commit()
    .then(() => {
      db.collection(`enviroment/prod/users`)
        .doc(action.data.emissorUid)
        .collection('session')
        .doc()
        .set(sessionCallEmisor)
        .then(() => {
          db.collection(`enviroment/prod/users`)
            .doc(action.data.receptorUid)
            .collection('session')
            .doc()
            .set(sessionCallReceptor)
            .then(() => {})
        })
    })
    .catch((e) => {
      console.error(e.toString())
    })
}

export function saveUserInBusiness(emisorCall, receptorCall) {
  const businessClient =
    emisorCall['businessClient'] !== null &&
    emisorCall['businessClient'] !== undefined
      ? emisorCall['businessClient']
      : []

  var businessClientFilter = businessClient.filter(
    (business) => business === receptorCall['business'][0]
  )
  if (businessClientFilter.length > 0) {
    console.info('cliente no agregado ya existe en la empresa')
  } else {
    var clienBusiness = {
      email: emisorCall['email'],
      uid: emisorCall['uid']
    }
    var listBussinesEmisor = businessClient
    listBussinesEmisor.push(receptorCall['business'][0])

    db.collection(`enviroment/prod/users`)
      .doc(emisorCall['uid'])
      .update({
        businessClient: listBussinesEmisor
      })
      .then(() => {})
      .catch(function (error) {
        console.error(error)
      })

    const increment = firebase.firestore.FieldValue.increment(1)

    const statsRef = db
      .collection(`enviroment/prod/business`)
      .doc(receptorCall['business'][0])
      .collection('client')
      .doc('--stats--')

    const sessionRef = db
      .collection(`enviroment/prod/business`)
      .doc(receptorCall['business'][0])
      .collection('client')
      .doc()

    const batch = db.batch()

    batch.set(sessionRef, clienBusiness)
    batch.set(statsRef, { clientCount: increment }, { merge: true })
    batch.commit().then(() => {})
  }
}
