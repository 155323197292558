import React, { Component } from 'react'
import AgoraRTC from 'agora-rtc-sdk-ng'
import { Row, Col } from 'reactstrap'
import { connect } from 'react-redux'
import HelperCall from '../../../helperCall'
import { helpers } from '../../../helpers'
import styles from './styles.css'
import classNames from 'classnames'
import CounterTime from './CounterTime'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompressAlt, faPhoneVolume } from '@fortawesome/free-solid-svg-icons'

import MicOffIcon from '@material-ui/icons/MicOff'
import MicIcon from '@material-ui/icons/Mic'
import VideocamOffIcon from '@material-ui/icons/VideocamOff'
import VideocamIcon from '@material-ui/icons/Videocam'
import { SessionTypes } from '../../../reducers/sesionRedux'

let interval = null
const APP_ID = '67796351b8ce4ed88e68d1e8809ac6b2'

var mediaStreamTrack

const rtc = {
  client: null,
  localAudio: null,
  localVideo: null
}

class Call extends Component {
  state = {
    activeMaxWindow: true,
    remoteStreams: [],
    remoteUsers: [],
    timeSecond: 0,
    isMute: false,
    isActiveVideo: true,
    emisor: null,
    receptor: null,
    audio: true,
    video: true,
    screen: false,
    activeMaxWindow: true,
    remoteStreams: [],
    receptorCall: [],
    emisorCall: [],
    timeSecond: 0
  }

  constructor(props) {
    super(props)
    rtc.client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' })
    console.warn(this.props.channel)
    this.initClient()
    this.setState({
      activeMaxWindow: true
    })
  }

  initClient = async () => {
    try {
      console.warn(this.props.channel)
      await rtc.client.join(APP_ID, this.props.channel, null)
      this.observable()
      this.initLocalStream() // validate media micro and video after join

      interval = setInterval(() => {
        this.setState({
          timeSecond: this.state.timeSecond + 1
        })
      }, 1000)
    } catch (e) {
      console.error('join failed', e)
    }
  }

  async componentDidMount() {
    var emisor = await helpers.getUserByUid(this.props.emissorUid)
    var receptor = await helpers.getUserByUid(this.props.receptorUid)
    this.setState({
      emisor: emisor,
      receptor: receptor
    })
  }

  async componentWillUnmount() {
    try {
      await this.leave()
    } catch (error) {
      console.error('componentWillUnmount')
      console.error(error)
    }
  }

  leave = async () => {
    try {
      // mediaStreamTrack.stop();
      rtc.localAudio.close()
      rtc.localVideo.close()
      await rtc.client.leave()

      /// vuelve al tamaño original
      if (!this.state.activeMaxWindow) {
        this.props.onClick()
      }
      clearInterval(interval)
      // leave the channel
    } catch (err) {
      console.error('---leave Error ---')
      console.error(err)
    }
  }

  observable = async () => {
    var me = this
    // Use the Agora Web SDK NG

    rtc.client.on('user-published', async (remoteUser, mediaType) => {
      await rtc.client.subscribe(remoteUser, mediaType)
      const videoTrackStats = rtc.client.getRemoteVideoStats()

      if (mediaType === 'video') {
        var remoteUid = `${remoteUser.uid}`

        var count = Object.keys(videoTrackStats).length

        var uniq = []
        if (count !== uniq.length) {
          var keyArray = Object.keys(videoTrackStats)
          uniq = keyArray
        } else {
          var arrayUser = me.state.remoteStreams
          arrayUser.push(remoteUid)
          uniq = [...new Set(arrayUser)]
        }

        me.setState({
          remoteStreams: uniq
        })

        remoteUser.videoTrack.play(remoteUid, {
          fit: 'contain'
        })
      }
      if (mediaType === 'audio') {
        console.warn('agora_remote_audio')
        remoteUser.audioTrack.play()
      }
    })

    rtc.client.on('user-unpublished', async (remoteUser, mediaType) => {
      try {
        if (mediaType == 'video') {
          remoteUser.videoTrack.stop()
        } else if (mediaType == 'audio') {
          remoteUser.audioTrack.stop()
        }
      } catch (error) {
        console.error(error)
      }

      const index = me.state.remoteStreams.findIndex(
        (item) => item === `${remoteUser.uid}`
      )
      if (index !== -1) {
        delete this.state.remoteStreams[index]
        me.setState({ remoteStreams: this.state.remoteStreams })
      } else {
        console.error('fail delete remote user')
      }
    })

    rtc.client.on('connection-state-change', (curState, prevState) => {})
  }

  initLocalStream = async () => {
    try {
      rtc.localAudio = await AgoraRTC.createMicrophoneAudioTrack()
      rtc.localVideo = await AgoraRTC.createCameraVideoTrack()

      // Remove this line if the channel profile is not live broadcast.
      // await rtc.client.setClientRole("host");
      await rtc.client.publish([rtc.localAudio, rtc.localVideo])

      rtc.localVideo.play('agora_local', {
        fit: 'contain'
      })
    } catch (e) {
      var message
      switch (e.code) {
        case 'NotFoundError':
        case 'DevicesNotFoundError':
          message = 'Please setup your webcam first.'
          break
        case 'SourceUnavailableError':
          message = 'Your webcam is busy'
          break
        case 'PermissionDeniedError':
        case 'SecurityError':
          message = 'Permission denied!'
          break
        default:
          this.errorMessage(
            'You have rejection of camera or microphone permits'
          )
          return
      }
      this.errorMessage(message)
    }
  }

  errorMessage(message) {
    Swal.fire('Permisos de tu navegador', message, 'error')
    console.error(message)
  }

  hangupCall(emisor, receptor) {
    var hours = new Date().getHours()
    var minutes = new Date().getMinutes()
    var seconds = new Date().getSeconds()
    var timeSecond = this.state.timeSecond
    var data = {
      receptorUid: receptor.uid,
      emissorUid: emisor.uid,
      timeQuery: hours + ':' + minutes + ':' + seconds,
      timeSecond: timeSecond
    }

    HelperCall.callingUpdate(emisor.uid, receptor.uid, false, false, false)
    HelperCall.pushHangupCall(emisor, receptor)
    this.props.saveSession(data)
  }

  refreshStream = () => {
    return (window.location.href = '/admin/video')
  }

  render() {
    return <div className={styles.smH100}>{this.viewAFullWindow()}</div>
  }

  viewLocal(isFull) {
    return (
      <div
        id='agora_local'
        style={{
          height: isFull ? '100%' : 125,
          width: '100%',
          overflow: 'hidden',
          perspective: '1px'
        }}
      ></div>
    )
  }

  viewAFullWindow() {
    var heightCard = this.state.activeMaxWindow ? '100%' : 125
    return (
      <div className={styles.smH100}>
        <Row className={classNames(styles.smH100, styles.h60Vh)}>
          <Col className={classNames(styles.smH100, 'col-sm-6', 'col-12')}>
            {this.viewLocal(this.state.activeMaxWindow)}
          </Col>
          <Col
            className={classNames(styles.remoteStream, 'col-sm-6', 'col-12')}
          >
            {this.state.remoteStreams.map((value, index) => {
              return (
                <div
                  key={value}
                  id={value}
                  style={{
                    height: heightCard,
                    width: '100%',
                    overflow: 'hidden',
                    perspective: '1px'
                  }}
                ></div>
              )
            })}
          </Col>
        </Row>
        <Row className={styles.containerButtonsCall}>{this.buttonAction()}</Row>
      </div>
    )
  }

  buttonAction = () => {
    const { emisor, receptor } = this.state
    return (
      <div className='col-lg-12' style={{}}>
        <div
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
          }}
        >
          <button
            style={{
              borderRadius: '100%',
              width: '50px',
              height: '50px',
              margin: '1%'
            }}
            className='btn btn-danger'
            onClick={() => this.hangupCall(emisor, receptor)}
          >
            <FontAwesomeIcon icon={faPhoneVolume} />{' '}
          </button>

          <button
            style={{
              borderRadius: '100%',
              width: '50px',
              height: '50px',
              margin: '1%'
            }}
            className='btn btn-info'
            onClick={() => {
              var data = !this.state.activeMaxWindow
              this.setState({
                activeMaxWindow: data
              })

              this.props.onClick()
            }}
          >
            <FontAwesomeIcon icon={faCompressAlt} />
          </button>

          <button
            style={{
              borderRadius: '100%',
              width: '50px',
              height: '50px',
              margin: '1%'
            }}
            className='btn btn-info'
            onClick={() => {
              rtc.localVideo.setEnabled(!this.state.isActiveVideo)
              this.setState({
                isActiveVideo: !this.state.isActiveVideo
              })
            }}
          >
            {this.state.isActiveVideo ? <VideocamIcon /> : <VideocamOffIcon />}
          </button>

          <button
            style={{
              borderRadius: '100%',
              width: '50px',
              height: '50px',
              margin: '1%'
            }}
            className='btn btn-info'
            onClick={() => {
              rtc.localAudio.setEnabled(!this.state.isMute)
              this.setState({
                isMute: !this.state.isMute
              })
            }}
          >
            {/* <FontAwesomeIcon icon={faMicrophone} /> */}
            {this.state.isMute ? <MicOffIcon /> : <MicIcon />}
          </button>
        </div>
        <CounterTime></CounterTime>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  saveSession: (data) => {
    dispatch({ type: SessionTypes.REQUEST_SESSION_SAVE, data: data })
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(Call)
