import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

const { Types, Creators } = createActions({
  requestSessionByUser: ["data"],
  requestSessionByAdviser: ["data"],
  receivedSessionByAdviser: ["data"],
  receivedSessionByUser: ["data"],
  requestSessionIndicator: ["data"],
  receivedSessionIndicator: ["data"],
  clearListSession: ["data"],
  requestSessionSave: ["data"],
  requestSendNotificationCall: ["data"],
});

export const SessionTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
  sessionListByAdviser: [],
  sessionListByUser: [],
  sessionListIndicator: [],
});

export const clearListSession = (state, { data }) => {
  return INITIAL_STATE;
};

export const requestSessionIndicator = (state, { data }) => {
  return state;
};

export const receivedSessionIndicator = (state, { data }) => {
  return Immutable.merge(state, {
    sessionListIndicator: data,
  });
};

export const requestSessionByAdviser = (state, { data }) => {
  return state;
};

export const requestSendNotificationCall = (state, { data }) => {
  return state;
};

export const receivedSessionByAdviser = (state, { data }) => {
  return Immutable.merge(state, {
    sessionListByAdviser: data,
  });
};

export const requestSessionByUser = (state, { data }) => {
  return state;
};

export const requestSessionSave = (state, { data }) => {
  return state;
};

export const receivedSessionByUser = (state, { data }) => {
  return Immutable.merge(state, {
    sessionListByUser: data,
  });
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CLEAR_LIST_SESSION]: clearListSession,
  [Types.REQUEST_SESSION_BY_ADVISER]: requestSessionByAdviser,
  [Types.RECEIVED_SESSION_BY_ADVISER]: receivedSessionByAdviser,
  [Types.REQUEST_SESSION_INDICATOR]: requestSessionIndicator,
  [Types.REQUEST_SESSION_SAVE]: requestSessionSave,
  [Types.REQUEST_SEND_NOTIFICATION_CALL]: requestSendNotificationCall,
  [Types.RECEIVED_SESSION_INDICATOR]: receivedSessionIndicator,
  [Types.REQUEST_SESSION_BY_USER]: requestSessionByUser,
  [Types.RECEIVED_SESSION_BY_USER]: receivedSessionByUser,
});
